AOS.init({});

const header = document.querySelector('#header');

const hideHeader = () => {
  header.classList.add('molac-header--header-stick');
};

const showHeader = () => {
  header.classList.remove('molac-header--header-stick');
};

let lastScrollTop = 0;
window.addEventListener('scroll', () => {
  const st = window.pageYOffset;
  st >= 200
    ? (hideHeader(),
      st < lastScrollTop &&
        header.classList.remove('molac-header--header-stick'),
      (lastScrollTop = st <= 0 ? 0 : st))
    : showHeader();
});
